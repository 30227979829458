const cache = new Map<string, string>();

// api-pccp-prod-eu-west-1.pccp.io gets replaced in the Dockerfile
const baseUrl = 'https://api-pccp-prod-eu-west-1.pccp.io/content-provider';

const getTariff = async (opts: TariffType | 'json' | TariffOptions, siteId?: string): Promise<string> => {
    const isV1Args = typeof opts === 'string';
    const type = isV1Args ? opts : opts.tariffType;

    const cacheKey = `tariff_${type}_${(!isV1Args && opts.siteId) || siteId || 'DEFAULT'}_${
        !isV1Args && opts.noMemberPrice ? 'NOMEM' : 'WITHMEM'
    }`;

    if (cache.has(cacheKey)) {
        return cache.get(cacheKey) as string;
    }

    const sitePath = !isV1Args && opts.siteId ? `/${opts.siteId}` : siteId ? `/${siteId}` : '';
    const noMemberPriceParam = !isV1Args && opts.noMemberPrice ? '&noMemberPrice=1' : '';

    const tariff = await (await fetch(`${baseUrl}/tariff${sitePath}?type=${type}${noMemberPriceParam}`)).text();

    if (!tariff) {
        throw new Error('Invalid response from tariff API');
    }

    cache.set(cacheKey, tariff);

    return tariff;
};

const getTariffHtml = async (opts: TariffType | 'json' | TariffOptions): Promise<string> => getTariff(opts);
const getTariffJson = async (siteId?: string): Promise<string> => getTariff('json', siteId);

const injectTariff = async (
    selector = '#anpriot_tariffs',
    opts: TariffType | 'json' | TariffOptions,
    headerOverrides?: Array<string | undefined>,
) => {
    const els = document.querySelectorAll(selector);

    // Return early if no elements match the selector.
    if (!els.length) {
        return;
    }

    // Get the tariff HTML from your existing function.
    const tariff = await getTariffHtml(opts);
    if (!tariff) {
        return;
    }

    // If no headerOverrides are provided or if it's not an array, inject the tariff as-is and return early.
    if (!headerOverrides || !Array.isArray(headerOverrides)) {
        for (const el of els) {
            el.innerHTML = tariff;
        }
        return;
    }

    // Create a temporary container to work with the fetched HTML.
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = tariff;

    // Locate the table within the temporary container.
    const table = tempContainer.querySelector('table');
    if (table) {
        // Try to find standard header cells (<th>).
        let headerCells = Array.from(table.querySelectorAll('th')) as HTMLElement[];
        // If no <th> elements are found, fall back to a custom class if your markup uses one.
        if (!headerCells || headerCells.length === 0) {
            headerCells = Array.from(table.querySelectorAll('.Anpriot_Tariff_Table_Head_Row_Cell')) as HTMLElement[];
        }

        // Loop through the headerOverrides array and update the corresponding header cell.
        if (headerCells && headerCells.length > 0) {
            headerOverrides.forEach((headerText, index) => {
                // Only modify the header if headerText is defined and a header cell exists at this index.
                const cell = headerCells[index];
                if (headerText !== undefined && cell) {
                    cell.innerHTML = headerText;
                }
            });
        }
    }

    // Now inject the modified HTML into all matching elements.
    for (const el of els) {
        el.innerHTML = tempContainer.innerHTML;
    }
};

const getTickets = async (type: TicketType | 'flexiJson' | 'seasonJson'): Promise<string> => {
    const cacheKey = `tickets_${type}`;

    if (cache.has(cacheKey)) {
        return cache.get(cacheKey) as string;
    }

    const tickets = await (await fetch(`${baseUrl}/tickets?type=${type}`)).text();

    if (!tickets) {
        throw new Error('Invalid response from tickets API');
    }

    cache.set(cacheKey, tickets);

    return tickets;
};

const getTicketsHtml = async (type: TicketType): Promise<string> => getTickets(type);
const getTicketsJson = async (type: TicketType): Promise<string> => {
    if (type === 'flexi') {
        return getTickets('flexiJson');
    } else if (type === 'season') {
        return getTickets('seasonJson');
    } else {
        throw new Error(`Invalid ticket type: ${type}`);
    }
};

const injectTickets = async (selector = '#anpriot_tickets', type: TicketType) => {
    const els = document.querySelectorAll(selector);

    if (els.length > 0) {
        const tickets = await getTickets(type);

        if (!tickets) {
            return;
        }

        for (const el of els) {
            el.innerHTML = tickets;
        }
    }
};

window.Anpriot = {
    getTariffHtml,
    getTariffJson,
    injectTariff,
    getTicketsHtml,
    getTicketsJson,
    injectTickets,
};
